<template>
  <div>
    <b-notification
        v-model="isError"
        type="is-danger"
        aria-close-label="Close notification"
        :closable="false">
        <b>Error fetching ingredient aisle!</b><br />Try refreshing the page. If the error persists check your network connection or let BM know.
    </b-notification>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div v-if="!isLoading && !isError" class="content">
      <div class="columns">
        <div class="column">
          <h1 class="title is-1">{{ aisle.name }}</h1>
        </div>
        <div class="column is-narrow">
          <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
        </div>
      </div>
      <b-field horizontal label="Aisle Name" message="* This will be seen by the end user in the shopping list">
          <b-input
            v-model="aisle.name"
            placeholder="e.g. eggs, dairy & bread"
            @input="saveDebounced" />
      </b-field>
      <b-field horizontal label="Position" message="* This is the aisle's position in the shopping list">
        <b-numberinput
          v-model="aisle.position"
          placeholder="e.g. 1"
          type="is-light"
          controls-position="compact"
          :min="1"
          @input="saveDebounced">
        </b-numberinput>
      </b-field>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import db from "@/firebase/db"

export default {
  name: 'IngredientAisleView',
  props: [
    'id'
  ],
  data() {
    return {
      isLoading: true,
      isError: false,
      aisle: {},
    };
  },
  created: function () {
    // fetch our aisle
    db.collection('ingredient_aisles')
      .doc(this.id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          this.aisle = snapshot.data();
          this.aisle.id = this.id;
        }else {
          this.isError = true; // show our error
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.isError = true;
        this.$buefy.notification.open({type: 'is-danger', message: `Error fetching ingredient aisle: ${error}`})
      });
  },
  methods: {
    saveDebounced: debounce(function () {
        this.save()
      }, 1000),
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('ingredient_aisles')
        .doc(this.id)
        .set(this.aisle)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Ingredient aisle successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving ingredient aisle: ${error}`})
        });
    },
  }
}
</script>
